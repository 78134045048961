import { action, observable } from 'mobx';
import { isArray, isNull } from 'lodash';
import { Option } from 'shared/types/shared';
// import { SelectOptionType } from 'shared/components/select/select';
import { DataTypeDefaultOption } from '../default-page_constant';
import RolesJSON from '../../../shared/static/roleAccess.json';
import { accountStore } from '../../account/stores/index';
import { makeObservable } from 'mobx';

export class DataStore {
    @observable dataTypes: Option[] = [];
    @observable selectedDataType: Option = DataTypeDefaultOption;
    @observable currentModuleName = '';

    constructor() {
        makeObservable(this)
    }

    init(dataTypeOptions): void {
        this.loadViewModel(dataTypeOptions);
    }

    async loadViewModel(dataTypeOptions): Promise<void> {
        this.setDataTypes(dataTypeOptions);
    }

    @action
    setDataTypes(dataTypes: Option[]): void {
        this.dataTypes = dataTypes;
    }
    // SelectOptionType
    setSelectedDataType(dataType: any): void {
        this.selectedDataType = !isNull(dataType) && !isArray(dataType) ? dataType : { label: '', value: '' };
    }

    @action
    reset(): void {
        this.setSelectedDataType(DataTypeDefaultOption);
    }

    @action
    async setModuleName(moduleName): Promise<void> {
        this.currentModuleName = moduleName;
    }

    getCurrentModuleName(): string {
        return this.currentModuleName;
    }

    checkOperationAccess(operationName): boolean {
        let access = false;
        const currentUserRoles = accountStore.userRoles;
        currentUserRoles.forEach(userRole => {
            if (!access) {
                const roleMapping = RolesJSON.find(
                    mapping => mapping.RoleName.toLowerCase() === userRole.toLowerCase()
                );
                const moduleInfo = roleMapping?.Modules.find(x => x.Name === this.currentModuleName);
                if (moduleInfo && !access) {
                    access = moduleInfo[operationName];
                }
            }
        });
        return access;
    }

    checkOperationAccessWithModule(operationName, currentModuleName): boolean {
        let access = false;
        const currentUserRoles = accountStore.userRoles;
        currentUserRoles.forEach(userRole => {
            const role = userRole;
            if (!access) {
                const roleMapping = RolesJSON.find(mapping => mapping.RoleName.toLowerCase() === role.toLowerCase());
                const moduleInfo = roleMapping?.Modules.find(x => x.Name === currentModuleName);
                if (moduleInfo && !access) {
                    access = moduleInfo[operationName];
                }
            }
        });
        return access;
    }

    configureDataTypeOptionsByRole(dataTypeOptions) {
        const accessDataTypes: any = [];
        dataTypeOptions.forEach(element => {
            if (element.value === 'Select') {
                accessDataTypes.push(element);
            } else {
                const checkAccess = this.checkOperationAccessWithModule('View', element.value);
                if (checkAccess) {
                    accessDataTypes.push(element);
                }
            }
        });
        return accessDataTypes;
    }
}
