import { uiService } from '../services/ui-service';

export function errorHandler(error: string) {
    uiService.toastService.error(error);
}
// 6/10/23
export function warningHandler(error: string) {
    uiService.toastService.warning(error);
}
// 30/10/23
export function successHandler(error: string) {
    uiService.toastService.success(error);
}