import { AuthToken } from '../types/shared';
import * as Msal from "@azure/msal-browser";;
import { Config } from '../../config';
import { AppConstant } from '../../app_constant';
import { isArray } from 'lodash';

export class AuthenticationService {
    private clientApplication: Msal.PublicClientApplication;
    private token = '';
    private config: Msal.Configuration = {
        auth: {
            clientId: Config.webClientID,  // 14/8/23
            redirectUri: Config.redirectURL,
            postLogoutRedirectUri: Config.redirectURL,
            authority: Config.msalEndpoint + Config.tenantID + '/'
        },
        cache: {
            cacheLocation: 'localStorage'
        }
    };

    constructor() {
        this.clientApplication = new Msal.PublicClientApplication(this.config);
    }

    async login(): Promise<AuthToken> {
        const clientApplication = this.clientApplication;
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        //const idToken: any = {
        //     "uniqueId": "5f5e5d89-0358-4474-b809-aa34d7b5e0d8",
        //     "tenantId": "e3267a76-a858-4ead-a71a-fa49332a6879",
        //     "tokenType": "id_token",
        //     "idToken": {
        //         "rawIdToken": "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6Ii1LSTNROW5OUjdiUm9meG1lWm9YcWJIWkdldyJ9.eyJhdWQiOiI1YTMwZjA5NS0yMDRhLTRlNTAtYjU5MS1hNGI4ZjNjNzIwNTQiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vZTMyNjdhNzYtYTg1OC00ZWFkLWE3MWEtZmE0OTMzMmE2ODc5L3YyLjAiLCJpYXQiOjE2ODY4MzU4NzQsIm5iZiI6MTY4NjgzNTg3NCwiZXhwIjoxNjg2ODY0OTc0LCJhaW8iOiJBVFFBeS84VEFBQUFUbzRSdHZMRWZwZGltZXR2RTZnS25POTNFZVpqNUZwMnpkNUU4RXpSYnRySTI0d3hhVWsyMUN6M3NNUWpOelc5IiwiaGFzZ3JvdXBzIjoidHJ1ZSIsIm5hbWUiOiJQYXRhbiBCIEtoYW4iLCJub25jZSI6IjMxNzQ2NDU5LTg5YjYtNDg1MS04OWJkLWU1NDhhMDFlNWE2NCIsIm9pZCI6IjVmNWU1ZDg5LTAzNTgtNDQ3NC1iODA5LWFhMzRkN2I1ZTBkOCIsInByZWZlcnJlZF91c2VybmFtZSI6IlBhdGFuLktoYW5AcGxhaW5zLmNvbSIsInJoIjoiMC5BVzhBZG5vbTQxaW9yVTZuR3ZwSk15cG9lWlh3TUZwS0lGQk90WkdrdVBQSElGUnZBS0kuIiwicm9sZXMiOlsicHRzaXMiXSwic3ViIjoiMzVWdFJDaktPQURWcWRQc212eFNHT0VDQ1I5LUZESndqSFVRWXhuQktLZyIsInRpZCI6ImUzMjY3YTc2LWE4NTgtNGVhZC1hNzFhLWZhNDkzMzJhNjg3OSIsInV0aSI6Im1VSWlLMDRDLWtHMXYwbE5YM1FVQUEiLCJ2ZXIiOiIyLjAifQ.YnK8h202JT9OFeL5i-FKBDDErsEu_Fvl2AgEjIuyhCiBKbKMeHWb95nD4gB7LXksgAGw97-npTwf5HB3b74IWqXHT5VTkM3RZ62QUYIUeCAB8SK36-flPu8lXZZWu-8EU9yUSsLgxNmLT8GH4d33ZUBPU-lqzg7d7M2pWjuQy_vFX1UCzt_muL5gBKAd5D-lcQ7LkpaSNUZj1v8pyA7f0NZVsZ38J4y_kSkJz6OSX0EKjfaS2aD6pbCqLG-JImYu0dfLUKVUPFCBWHK8Udzcr4Dp7VaHShPBsb92DWjCulS9ebZU8YTkAzAz2c08cYZVy0f9tGVMd27oe3XoW2XsRw",
        //         "claims": {
        //             "aud": "5a30f095-204a-4e50-b591-a4b8f3c72054",
        //             "iss": "https://login.microsoftonline.com/e3267a76-a858-4ead-a71a-fa49332a6879/v2.0",
        //             "iat": 1686835874,
        //             "nbf": 1686835874,
        //             "exp": 1686864974,
        //             "aio": "ATQAy/8TAAAATo4RtvLEfpdimetvE6gKnO93EeZj5Fp2zd5E8EzRbtrI24wxaUk21Cz3sMQjNzW9",
        //             "hasgroups": "true",
        //             "name": "Patan B Khan",
        //             "nonce": "31746459-89b6-4851-89bd-e548a01e5a64",
        //             "oid": "5f5e5d89-0358-4474-b809-aa34d7b5e0d8",
        //             "preferred_username": "Patan.Khan@plains.com",
        //             "rh": "0.AW8Adnom41iorU6nGvpJMypoeZXwMFpKIFBOtZGkuPPHIFRvAKI.",
        //             "roles": [
        //                 "ptsis"
        //             ],
        //             "sub": "35VtRCjKOADVqdPsmvxSGOECCR9-FDJwjHUQYxnBKKg",
        //             "tid": "e3267a76-a858-4ead-a71a-fa49332a6879",
        //             "uti": "mUIiK04C-kG1v0lNX3QUAA",
        //             "ver": "2.0"
        //         },
        //         "issuer": "https://login.microsoftonline.com/e3267a76-a858-4ead-a71a-fa49332a6879/v2.0",
        //         "objectId": "5f5e5d89-0358-4474-b809-aa34d7b5e0d8",
        //         "subject": "35VtRCjKOADVqdPsmvxSGOECCR9-FDJwjHUQYxnBKKg",
        //         "tenantId": "e3267a76-a858-4ead-a71a-fa49332a6879",
        //         "version": "2.0",
        //         "preferredName": "Patan.Khan@plains.com",
        //         "name": "Patan B Khan",
        //         "nonce": "31746459-89b6-4851-89bd-e548a01e5a64",
        //         "expiration": 1686864974
        //     },
        //     "idTokenClaims": {
        //         "aud": "5a30f095-204a-4e50-b591-a4b8f3c72054",
        //         "iss": "https://login.microsoftonline.com/e3267a76-a858-4ead-a71a-fa49332a6879/v2.0",
        //         "iat": 1686835874,
        //         "nbf": 1686835874,
        //         "exp": 1686864974,
        //         "aio": "ATQAy/8TAAAATo4RtvLEfpdimetvE6gKnO93EeZj5Fp2zd5E8EzRbtrI24wxaUk21Cz3sMQjNzW9",
        //         "hasgroups": "true",
        //         "name": "Patan B Khan",
        //         "nonce": "31746459-89b6-4851-89bd-e548a01e5a64",
        //         "oid": "5f5e5d89-0358-4474-b809-aa34d7b5e0d8",
        //         "preferred_username": "Patan.Khan@plains.com",
        //         "rh": "0.AW8Adnom41iorU6nGvpJMypoeZXwMFpKIFBOtZGkuPPHIFRvAKI.",
        //         "roles": [
        //             "ptsis"
        //         ],
        //         "sub": "35VtRCjKOADVqdPsmvxSGOECCR9-FDJwjHUQYxnBKKg",
        //         "tid": "e3267a76-a858-4ead-a71a-fa49332a6879",
        //         "uti": "mUIiK04C-kG1v0lNX3QUAA",
        //         "ver": "2.0"
        //     },
        //     "accessToken": null,
        //     "scopes": [],
        //     "expiresOn": "2023-06-15T21:36:14.000Z",
        //     "account": {
        //         "accountIdentifier": "5f5e5d89-0358-4474-b809-aa34d7b5e0d8",
        //         "homeAccountIdentifier": "NWY1ZTVkODktMDM1OC00NDc0LWI4MDktYWEzNGQ3YjVlMGQ4.ZTMyNjdhNzYtYTg1OC00ZWFkLWE3MWEtZmE0OTMzMmE2ODc5",
        //         "userName": "Patan.Khan@plains.com",
        //         "name": "Patan B Khan",
        //         "idToken": {
        //             "aud": "5a30f095-204a-4e50-b591-a4b8f3c72054",
        //             "iss": "https://login.microsoftonline.com/e3267a76-a858-4ead-a71a-fa49332a6879/v2.0",
        //             "iat": 1686835874,
        //             "nbf": 1686835874,
        //             "exp": 1686864974,
        //             "aio": "ATQAy/8TAAAATo4RtvLEfpdimetvE6gKnO93EeZj5Fp2zd5E8EzRbtrI24wxaUk21Cz3sMQjNzW9",
        //             "hasgroups": "true",
        //             "name": "Patan B Khan",
        //             "nonce": "31746459-89b6-4851-89bd-e548a01e5a64",
        //             "oid": "5f5e5d89-0358-4474-b809-aa34d7b5e0d8",
        //             "preferred_username": "Patan.Khan@plains.com",
        //             "rh": "0.AW8Adnom41iorU6nGvpJMypoeZXwMFpKIFBOtZGkuPPHIFRvAKI.",
        //             "roles": [
        //                 "ptsis"
        //             ],
        //             "sub": "35VtRCjKOADVqdPsmvxSGOECCR9-FDJwjHUQYxnBKKg",
        //             "tid": "e3267a76-a858-4ead-a71a-fa49332a6879",
        //             "uti": "mUIiK04C-kG1v0lNX3QUAA",
        //             "ver": "2.0"
        //         },
        //         "idTokenClaims": {
        //             "aud": "5a30f095-204a-4e50-b591-a4b8f3c72054",
        //             "iss": "https://login.microsoftonline.com/e3267a76-a858-4ead-a71a-fa49332a6879/v2.0",
        //             "iat": 1686835874,
        //             "nbf": 1686835874,
        //             "exp": 1686864974,
        //             "aio": "ATQAy/8TAAAATo4RtvLEfpdimetvE6gKnO93EeZj5Fp2zd5E8EzRbtrI24wxaUk21Cz3sMQjNzW9",
        //             "hasgroups": "true",
        //             "name": "Patan B Khan",
        //             "nonce": "31746459-89b6-4851-89bd-e548a01e5a64",
        //             "oid": "5f5e5d89-0358-4474-b809-aa34d7b5e0d8",
        //             "preferred_username": "Patan.Khan@plains.com",
        //             "rh": "0.AW8Adnom41iorU6nGvpJMypoeZXwMFpKIFBOtZGkuPPHIFRvAKI.",
        //             "roles": [
        //                 "ptsis"
        //             ],
        //             "sub": "35VtRCjKOADVqdPsmvxSGOECCR9-FDJwjHUQYxnBKKg",
        //             "tid": "e3267a76-a858-4ead-a71a-fa49332a6879",
        //             "uti": "mUIiK04C-kG1v0lNX3QUAA",
        //             "ver": "2.0"
        //         },
        //         "environment": "https://login.microsoftonline.com/e3267a76-a858-4ead-a71a-fa49332a6879/v2.0"
        //     },
        //     "accountState": "eyJpZCI6IjY4NmFiOGY0LTU4ZTktNGUwZi04MzE1LWE2ZTFmZjI3Njk5MyIsInRzIjoxNjg2ODM2MTc1LCJtZXRob2QiOiJwb3B1cEludGVyYWN0aW9uIn0=",
        //     "fromCache": false
        // }; 
        const idToken: any = await clientApplication.loginPopup();
        clientApplication.setActiveAccount(idToken.account);  // 25/7/2023
        return Promise.resolve({
            value: idToken.idToken,
            userName: idToken.account.username,
            displayName: idToken.account.name,
            expiry: idToken.expiresOn,
            roles: this.getUserRoles(idToken.account.idTokenClaims.roles)
        });
    }

    async acquireTokenSilent(): Promise<AuthToken> {
        // const loginRequest = {
        //     scopes: [Config.apiClientID]
        // };
        const loginRequest = {
            client_id: Config.apiClientID,  // 14/8/23
            client_secret: Config.apiClientSecrest,  // 14/8/23
            grant_type: Config.apiGrantType,  // 14/8/23
            resource: Config.apiClientID,  // 14/8/23
            redirect_uri: Config.redirectURL,  // 14/8/23
            scopes: [Config.apiScopes],  // 14/8/23
        }; // 25/7/2023
        const clientApplication = this.clientApplication;
        const idToken: any = await clientApplication.acquireTokenSilent(loginRequest);

        // return Promise.resolve({
        //     value: '',
        //     userName: 'Patan.Khan@plains.com',
        //     roles: [
        //         "ptsis"
        //     ],
        //     displayName: 'Patan B Khan',
        //     expiry: '2023-06-15T21:36:14.000Z'
        // });
        return Promise.resolve({
            value:
                idToken.accessToken === '' || idToken.accessToken === null
                    ? idToken.idToken
                    : idToken.accessToken,
            userName: idToken.account.username,
            displayName: idToken.account.name,
            expiry: idToken.expiresOn,
            roles: this.getUserRoles(idToken.account.idTokenClaims.roles)
        });
    }

    async acquireTokenPopup(): Promise<AuthToken> {
        // const loginRequest = {
        //     scopes: [Config.apiClientID]
        // };
        const loginRequest = {
            client_id: Config.apiClientID,  // 14/8/23
            client_secret: Config.apiClientSecrest,  // 14/8/23
            grant_type: Config.apiGrantType,  // 14/8/23
            resource: Config.apiClientID,  // 14/8/23
            redirect_uri: Config.redirectURL,  // 14/8/23
            scopes: [Config.apiScopes],  // 14/8/23
        }; // 25/7/2023
        const clientApplication = this.clientApplication;
        const idToken: any = await clientApplication.acquireTokenPopup(loginRequest);
        return Promise.resolve({
            value:
                idToken.accessToken === '' || idToken.accessToken === null
                    ? idToken.idToken.rawIdToken
                    : idToken.accessToken,
            userName: idToken.account.username,
            displayName: idToken.account.name,
            expiry: idToken.expiresOn,
            roles: this.getUserRoles(idToken.account.idToken.roles)
        });
        // return Promise.resolve({
        //     value: '',
        //     userName: 'Patan.Khan@plains.com',
        //     roles: [
        //         "ptsis"
        //     ],
        //     displayName: 'Patan B Khan',
        //     expiry: '2023-06-15T21:36:14.000Z'
        // });
    }

    // If user has Is Role, it automatically get all other permission of APPROLES
    getUserRoles(roles: string[]) {
        let newRoles: string[] = [];

        if (roles && isArray(roles)) {
            newRoles = roles;
        }
        return newRoles;
    }

    public logout(): void {
        this.clientApplication.logout();
    }

    public isOnline(): boolean {
        return this.clientApplication.getActiveAccount() != null;
        // return true;
    }
    // Msal.Account
    public getUser(): any {
        return this.clientApplication.getActiveAccount();
        //return null;
    }
}

export const authenticationService = new AuthenticationService();
