import { restApiService } from '../../../shared/services';
import { RestApi, AuthToken } from '../../../shared/types/shared';
import { LocalStorageService, localStorageService } from '../../../shared/services/local-storage-service';
import { DataService } from './data-service';
import { AppConstant } from '../../../app_constant';
import { AuthenticationService, authenticationService } from '../../../shared/services/authentication-service';
import { SignUp } from '../models/signup';
import { Config } from '../../../config';
import { uiService } from '../../../shared/services/ui-service';
import { InteractionRequiredAuthError } from 'msal';

export class AccountDataService implements DataService {
    constructor(
        private restApi: RestApi,
        private localStorageService: LocalStorageService,
        private authenticationService: AuthenticationService
    ) { }

    signUpUser(data: SignUp): Promise<any> {
        return this.restApi.post<any>(Config.createUser, data);
    }

    async login(): Promise<AuthToken> {
        try {
            const token = await this.authenticationService.login();
            this.localStorageService.set(AppConstant.AUTHTOKEN, token);
            this.localStorageService.set(AppConstant.LOGINTIME, new Date());
            return token;
        } catch (e) {
            if (e instanceof InteractionRequiredAuthError) {
                uiService.toastService.error(AppConstant.UNAUTHORIZED_USER);
            }
            return Promise.reject();
        }
    }

    logout(): void {
        this.authenticationService.logout();
    }
}

export const accountDataService = new AccountDataService(restApiService, localStorageService, authenticationService);
