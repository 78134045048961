import * as React from "react";
import { ColorRing } from 'react-loader-spinner';
import './_loader.scss';
import { loaderSubject } from '.';

export interface ReactLoaderProps {
    visible: boolean;
}

export interface ReactLoaderState {
    visible: boolean;
}

export class ReactLoader extends React.Component<ReactLoaderProps, ReactLoaderState> {
    constructor(props: Readonly<ReactLoaderProps>) {
        super(props);
        this.state = {
            visible: props.visible
        };
        loaderSubject.subscribe((visible: boolean) => {
            this.setState({ visible });
        });
    }

    componentDidUpdate(newProps: { visible: boolean }) {
        if (this.props.visible !== newProps.visible) {
            loaderSubject.next(newProps?.visible);
        }
    }

    componentWillUnmount() {
        loaderSubject.unsubscribe();
    }

    public render(): React.ReactNode {
        const { visible } = this.state;
        return (
            <React.Fragment>
                {visible ? <div className="loader__overlay" /> : null}
                <div className="loader__icon">
                    <ColorRing
                        visible={visible}
                        height="80"
                        width="80"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                    />
                </div>
            </React.Fragment>
        );
    }
}
