import * as React from "react";
import { PathRouteProps, RouteProps } from 'react-router';
import { AccountStore } from '../../features/account/stores/account-store';
import ErrorBoundary from './error-boundary/error-boundary';
import { Config } from '../../config';
import withRouter from '../../withRouter';
import { Navigate } from 'react-router-dom'; //1/12/23
export interface ProtectedRouteProps extends PathRouteProps {
    accountStore: AccountStore;
    component: React.ComponentClass<any>;
    roles: string[];
    location: any;
    history: any;
    navigate?: any;
}

export class ProtectedRouteComponent extends React.Component<ProtectedRouteProps & RouteProps> {
    public render(): React.ReactNode {
        const { isLoggedIn, getisLoggedIn } = this.props.accountStore; //1/12/23
        const { component: Component, roles, location, accountStore, history } = this.props;
        const isAccessAllowed = accountStore.isLoggedInUserHasRoleToAccessAView(roles);
        const role = accountStore.getRoleForAView(roles);
        const newProps: any = { role };
        if (!getisLoggedIn) { //1/12/23
            const { pathname, search } = location;
            const path = {
                pathname: '/login',
                state: { from: { pathname, search } }
            };
            // history.push(path);
            // this.props?.navigate(path);
            return <Navigate to='/login'/>
            // return this.props?.navigate(path);
        }
        const userInfo = accountStore.getUserInfo();
        if (role && isLoggedIn && userInfo && userInfo.isPSA && !userInfo.isPTS) {
            window.location.href = Config.psaWebUrl;
        }
        if (!isAccessAllowed) {
            return null;
        }
        return (
            <ErrorBoundary>
                <Component {...newProps} />
            </ErrorBoundary>
        );
    }
}

const ProtectedRoute = withRouter(ProtectedRouteComponent);
export default ProtectedRoute;
