import './index.scss';
// // TODO Move all css ref to different file
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
// import 'react-datepicker/dist/react-datepicker.css';
// import 'react-day-picker/lib/style.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './App.scss';

import { accountStore } from './features/account/stores';
import { dataStore } from 'features/common/stores';

import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'mobx-react';
import * as serviceWorker from './serviceWorker';
import { localStorageService } from 'shared/services/local-storage-service';
import { AuthToken } from 'shared/types/shared';
import { AppConstant } from 'app_constant';

const rootStore = {
  dataStore,
  accountStore,
  // trailerMaintenanceStore,
  // inspectionDataStore,
  // mappingDataStore,
  // ticketStore,
  // userApprovalStore,
  // dispatchBoardDataStore,
  // capacityPlanDataStore,
  // hazardAssessmentDataStore
};

const authTokenOnPageLoad = localStorageService.get<AuthToken>(AppConstant.AUTHTOKEN);
if (authTokenOnPageLoad) {
  accountStore.setAuthInfo(authTokenOnPageLoad, true);
}
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider {...rootStore}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// serviceWorker.unregister();

