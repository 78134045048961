import { AxiosRequestConfig } from 'axios';
import { ResponseDataType, RestApi, AuthToken, StorageService, LoggedInAuthToken } from '../types/shared';
import { HttpMethodName } from '../types/enum';
import { http } from './axios-util';
import { AppConstant } from '../../app_constant';
import { authenticationService, AuthenticationService } from './authentication-service';
import { Subject } from 'rxjs';
import moment from 'moment';
import { accountSubscription } from './account-subscription-service';
import { localStorageService, LocalStorageService } from './local-storage-service';

export class RestApiService implements RestApi {
    constructor(
        private localStorageService: LocalStorageService,
        private accountSubscription: Subject<LoggedInAuthToken>,
        private authenticationService: AuthenticationService
    ) { }
    get<T>(url: string, data: {} = {}, config: AxiosRequestConfig = {}): Promise<ResponseDataType<T>> {
        const options = {
            url,
            data,
            method: HttpMethodName.GET,
            headers: { 'Access-Control-Allow-Origin': '*' }
        };
        return http.request<T>({ ...options, ...config });
    }

    async getTokenValue() {
        let token: AuthToken | undefined;
        try {
            token = await this.authenticationService.acquireTokenSilent();
            this.localStorageService.set(AppConstant.AUTHTOKEN, token);
            return token ? token.value : '';
        } catch (e) {
            try {
                token = await this.authenticationService.acquireTokenPopup();
                this.localStorageService.set(AppConstant.AUTHTOKEN, token);
                return token ? token.value : '';
            } catch (e) {
                this.accountSubscription.next(AppConstant.DEFAULT_USER_DETAILS);
            }
        }
        return token;
    }

    async getAuthToken(): Promise<AuthToken> {
        return await this.authenticationService.acquireTokenSilent();
    }

    async getWithToken<T>(url: string, data: {} = {}, config: AxiosRequestConfig = {}): Promise<ResponseDataType<T>> {
        const token = await this.getTokenValue();
        if (!token) {
            return Promise.reject();
        }
        const options = {
            url,
            data,
            method: HttpMethodName.GET,
            headers: { authorization: 'Bearer ' + token }
        };
        return http.request<T>({ ...options, ...config });
    }

    post<T>(url: string, data: {} = {}, config: {} = {}): Promise<ResponseDataType<T>> {
        const options = {
            url,
            data,
            method: HttpMethodName.POST
        };
        return http.request<T>({ ...options, ...config });
    }

    async postWithToken<T>(url: string, data: {} = {}, config: {} = {}): Promise<ResponseDataType<T>> {
        // const token: AuthToken | undefined = this.localStorageService.get(AppConstant.AUTHTOKEN);
        let idtoken: any;
        // if (token && token.value && moment().toISOString() < token.expiry) {
        //     idtoken = token.value;
        // } else {
        idtoken = await this.getTokenValue();
        // }
        if (!idtoken) {
            return Promise.reject();
        }

        const options = {
            url,
            data,
            method: HttpMethodName.POST,
            headers: { authorization: 'Bearer ' + idtoken }
        };
        return http.request<T>({ ...options, ...config });
    }
}

export const restApiService = new RestApiService(localStorageService, accountSubscription, authenticationService);
