/* eslint-disable @typescript-eslint/no-explicit-any */
// WEB_APP_HOST: 'https://pmc-dev-noncore-enhancedtrucking-dispatch-webapp.azurewebsites.net',
// API_CLIENT_ID: '8291f4c6-ef7d-43a7-8358-4e16b0b120e8',
// TENANT_ID: 'e3267a76-a858-4ead-a71a-fa49332a6879',
// MSAL_ENDPOINT: 'https://login.microsoftonline.com/',
// PHP_GRID_URL:
//     'https://pmc-dev-noncore-enhancedtrucking-dispatch-webapp.azurewebsites.net/dispatchgrid/datamanagement/',
// FUNCTION_APP_HOST: 'https://pmc-dev-noncore-enhancedtrucking-functionapp.azurewebsites.net/api/'
// FUNCTION_APP_HOST: 'https://pna-scu-noncore-001-dev-productquality-functioncapp.azurewebsites.net/',
// FUNCTION_APP_KEY: 'BfXyuo15nC7fhuNjDMQDLt6coS5pRQqEpsPlk5bxwFTE5KarC9G8aQ==',
// PDS_DEPLOY_DATE: 'Date'

const webClientID = (window as any).hostName.WEB_CLIENT_ID; // 14/8/23
const tenantID = (window as any).hostName.TENANT_ID;
const secretID = (window as any).hostName.API_CLIENT_SECRET_ID;
const resourceId = (window as any).hostName.RESOURCE_ID;
const WebAppServerUrl = (window as any).hostName.WEB_APP_HOST;
const msalEndpoint = (window as any).hostName.MSAL_ENDPOINT;
const FunctionAppHostName = (window as any).hostName.FUNCTION_APP_HOST;
const functionAppKey = (window as any).hostName.FUNCTION_APP_KEY;
const psaWebUrl = (window as any).hostName.PSA_WEB_URL;
const deployDate = (window as any).hostName.PDS_DEPLOY_DATE;
const buildInfo = (window as any).hostName.BUILD_INFO;
const apiClientID = (window as any).hostName.API_CLIENT_ID; // 14/8/23
const apiClientSecrest = (window as any).hostName.API_CLIENT_SECREST; // 14/8/23
const apiGrantType = (window as any).hostName.API_GRANT_TYPE; // 14/8/23
const apiScopes = (window as any).hostName.API_SCOPES; // 14/8/23
export const Config = {
    webClientID, // 14/8/23
    tenantID,
    msalEndpoint,
    functionAppKey,
    psaWebUrl,
    buildInfo,
    deployDate,
    redirectURL: `${WebAppServerUrl}/login`,  // 25/7/2023
    apiClientID, // 14/8/23
    apiClientSecrest, // 14/8/23
    apiGrantType, // 14/8/23
    apiScopes, // 14/8/23

    // user
    getUser: `${FunctionAppHostName}GetUser?code=${functionAppKey}`,
    getLoginUser: `${FunctionAppHostName}GetLoginUser?code=${functionAppKey}`,
    updateUserStatusForApproval: `${FunctionAppHostName}UpdateUserStatusForVerifiedUser?code=${functionAppKey}`,

    // create user
    createUser: `${FunctionAppHostName}CreateUser?code=${functionAppKey}`,
    getFacility: `${FunctionAppHostName}GetFacility?code=${functionAppKey}`,

    // audit trail
    getBaseValueByClass: `${FunctionAppHostName}GetConfigValueByClass?code=${functionAppKey}`,
    getAuditData: `${FunctionAppHostName}GetAuditData?code=${functionAppKey}`,
    updateAuditTrailInfos: `${FunctionAppHostName}UpdateInspectionTickets?code=${functionAppKey}`,

    // edi data
    getEditDataInfoList: `${FunctionAppHostName}GetInspectionTicketHistory?code=${functionAppKey}`,
    updateEditDataInfos: `${FunctionAppHostName}UpdateInspectionTickets?code=${functionAppKey}`,

    // sample result
    getSampleResultReport: `${FunctionAppHostName}GetMasterTicketReport?code=${functionAppKey}`,
    // 9/11/23
    getSPId: `${FunctionAppHostName}GetSamplePointByID?code=${functionAppKey}`,
    // edit api 11/7/23
    getAllProducts: `${FunctionAppHostName}GetAllProducts?code=${functionAppKey}`,
    getAllLabs: `${FunctionAppHostName}GetAllLabs?code=${functionAppKey}`,
    getAllSP: `${FunctionAppHostName}GetAllSamplePoints?code=${functionAppKey}`, // 30/10/23
    getAllSamplePoints: `${FunctionAppHostName}GetSystemSamplePoint?code=${functionAppKey}`,
    getSamplePointSystems: `${FunctionAppHostName}GetSystem?code=${functionAppKey}`,
    // 03/10/23
    getAllQS: `${FunctionAppHostName}GetAllQualitySpecs?code=${functionAppKey}`, //17/10/23
    getAllMethod: `${FunctionAppHostName}GetAllMethods?code=${functionAppKey}`, // 9/10/23
    getAllParam: `${FunctionAppHostName}GetAllParameters?code=${functionAppKey}`, // 9/10/23
    getAllUom: `${FunctionAppHostName}GetAllUOMs?code=${functionAppKey}`, // 9/10/23
    getAllFacility: `${FunctionAppHostName}GetAllSamplePoints?code=${functionAppKey}`,
    getFacilityForSP: `${FunctionAppHostName}GetFacilities?code=${functionAppKey}`, // 30/10/23
    // 11/10/23
    getAllSystem: `${FunctionAppHostName}GetAllSystems?code=${functionAppKey}`,
    saveEditSystemReftableData: `${FunctionAppHostName}AddUpdateSystemsTable?code=${functionAppKey}`,
    // 17/10/23
    saveEditQSReftableData: `${FunctionAppHostName}AddUpdateQualitySpecsTable?code=${functionAppKey}`, //17/10/23
    saveEditLabReftableData: `${FunctionAppHostName}AddUpdateLabTable?code=${functionAppKey}`, //17/10/23
    // 7/13/23
    updateSampleData: `${FunctionAppHostName}UpdateSample?code=${functionAppKey}`,

    // sample api
    getSampleResult: `${FunctionAppHostName}GetAllSamplesData?code=${functionAppKey}`, // 17/11/23
    getAdhocResult: `${FunctionAppHostName}GetAllSamples?code=${functionAppKey}`, // 17/11/23
    getDispatchDetails: `${FunctionAppHostName}GetDispatchTicketsForMasterReport?code=${functionAppKey}`,
    getPSACarrierWithDispatcher: `${FunctionAppHostName}GetPSACarrierWithDispatcher?code=${functionAppKey}`,
    getUserToApproveForDispatcher: `${FunctionAppHostName}GetUserDetailForDispatcher?code=${functionAppKey}`,
    UpdateUserStatusByDispatcher: `${FunctionAppHostName}UpdateUserStatusByDispatcher?code=${functionAppKey}`,

    // Ad hoc queries
    getAdHocQueriesReport: `${FunctionAppHostName}GetSamplesMatrix?code=${functionAppKey}`,

    // Automated Reports
    getAutomatedReportData: `${FunctionAppHostName}GenerateReport?code=${functionAppKey}`,
    // 03/10/23
    saveEditProductReftableData: `${FunctionAppHostName}AddUpdateProductTable?code=${functionAppKey}`,
    // 9/10/23
    saveEditUOMReftableData: `${FunctionAppHostName}AddUpdateUOMTable?code=${functionAppKey}`,
    saveEditParamReftableData: `${FunctionAppHostName}AddUpdateParameterTable?code=${functionAppKey}`,
    saveEditMethodReftableData: `${FunctionAppHostName}AddUpdateMethodTable?code=${functionAppKey}`,
    // 6/11/23
    saveEditSPReftableData: `${FunctionAppHostName}AddUpdateSamplePointTable?code=${functionAppKey}`,
    //17/11/23
    getLabRefId: `${FunctionAppHostName}GetSampleRefIndexAPI?code=${functionAppKey}`,
    saveSampleandResult: `${FunctionAppHostName}CreateSampleResult?code=${functionAppKey}`,

};
