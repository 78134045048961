import * as React from "react";
import './_header-component.scss';
import logo from '../../../../assets/Logos -_Plains - Full Color.png';
import { NavLink, PathRouteProps } from "react-router-dom";
import { Navbar, Nav, Dropdown } from 'react-bootstrap';
import { CustomToggle } from './custom-toggle';
import { dataStore } from '../../../../features/common/stores/index';
import { accountStore } from '../../../../features/account/stores';
// import { Config } from '../../../../config';
import moment from 'moment';
import { localStorageService } from '../../../services/local-storage-service';
// import { CCUserRoles, plainsRoles } from '../../../../features/dispatch/communication/communication-constants';
import { AppConstant } from '../../../../app_constant';
import { AuthToken } from '../../../types/shared';
import { restApiService } from '../../../services/rest-api-service';
import withRouter from '../../../../withRouter';
export interface HeaderComponentProps extends PathRouteProps {
    onLogout: () => void;
    roles: string[];
    userName: string;
    location?: any; // menu hide path = /login // 5/9/23 //11/12/23
}

interface HeaderComponentState {
    expanded: boolean;
    count: number;
}

class HeaderComponent extends React.Component<HeaderComponentProps, HeaderComponentState> {
    count;
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            count: 0
        };
        const intervalId = setInterval(() => {
            const data: any = localStorageService.get('Communication_Direct_' + accountStore.getUserID());
            if (localStorageService.get('Communication_Direct_' + accountStore.getUserID())) {
                let count = 0;
                for (let i = 0; i < data.length; i++) {
                    for (let j = 0; j < data[i].Data.length; j++) {
                        if (
                            !this.checkCCLogin()
                                ? !data[i].Data[j].IsRead &&
                                data[i].Data[j].SenderID !== accountStore.getUserID()
                                // &&
                                // !plainsRoles.includes(data[i].Data[j].SenderRole)
                                : !data[i].Data[j].IsRead && data[i].Data[j].SenderID !== accountStore.getUserID()
                        ) {
                            count++;
                        }
                    }
                }
                this.count = count;
                this.handleSelect();
            }
            if (this.checkCCLogin()) {
                if (localStorageService.get('Communication_BroadCast_' + accountStore.getUserID())) {
                    const broadcastData: any = localStorageService.get(
                        'Communication_BroadCast_' + accountStore.getUserID()
                    );
                    let count = 0;
                    for (let i = 0; i < broadcastData.length; i++) {
                        if (!broadcastData[i].IsRead && broadcastData[i].SenderID !== accountStore.getUserID()) {
                            count++;
                        }
                    }
                    this.count += count;
                    this.handleSelect();
                }
            }
        }, 20000);

        setInterval(async () => {
            const idtoken: any = await restApiService.getTokenValue();
            const token: AuthToken | undefined = localStorageService.get(AppConstant.AUTHTOKEN);
        }, 3000000);
    }

    handleToggle = () => {
        this.setState({
            expanded: !this.state.expanded
        });
    };

    handleSelect = () => {
        this.setState({ expanded: false });
    };

    public render(): React.ReactNode {
        return (
            // menu hide path = /login  // 5/9/23 //11/12/23
            <>
                {this.props?.location.pathname !== '/login' ?
                    <Navbar onToggle={this.handleToggle} expanded={this.state.expanded} expand="sm" className="header">
                        {this.renderLogo()}
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            {this.renderNavigation()}
                            {this.renderProfile()}
                        </Navbar.Collapse>
                    </Navbar>
                    : null}
            </>
        );
    }

    renderLogo(): React.ReactNode {
        return (
            <div className="header__logo">
                <NavLink to="/">
                    <img className="header-logo" src={logo} alt="Logo" />
                </NavLink>
            </div>
        );
    }

    renderProfile(): React.ReactNode {
        return (
            <div className="header__user-info d-none d-sm-flex">
                <div className="header__user-text">Welcome, {this.props.userName}</div>
                {this.renderData()}
            </div>
        );
    }

    renderData(): React.ReactNode {
        return (
            <Dropdown>
                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                    <div className="header__user-img">
                        <div className="header__user-img-text">{this.props.userName.charAt(0)}</div>
                    </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-right">
                    <Dropdown.Item>
                        <div className="header__menu" onClick={this.props.onLogout}>
                            Logout
                        </div>
                    </Dropdown.Item>
                    {/* <Dropdown.Item>
                        <div className="header__menu">{this.getBuildInfo(Config.buildInfo)} ({this.getDeployDate(Config.deployDate)})</div>
                    </Dropdown.Item> */}
                </Dropdown.Menu>
            </Dropdown>
        );
    }

    getUserRole(roleRequires: string[], rolesAssigned: string[]): string {
        const isRolePresent = roleRequires.some(role => rolesAssigned.includes(role));
        return isRolePresent ? rolesAssigned[0] : '';
    }
    checkCCLogin() {
        let access = false;
        const currentUserRoles = accountStore.userRoles;
        currentUserRoles.forEach(userRole => {
            if (!access) {
                // if (CCUserRoles.includes(userRole)) {
                //     access = true;
                // }
            }
        });
        return access;
    }

    getBuildInfo(buildInfo) {

        return buildInfo;
    }

    getDeployDate(deployDate) {
        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const formatdata = moment(deployDate, 'DD/MM/YYYY');
        const date = formatdata.toDate();
        return monthNames[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
    }

    renderNavigation(): React.ReactNode {
        const userInfo = accountStore.getUserInfo();
        return (
            <Nav className="ml-3 d-none d-sm-block">
                {dataStore.checkOperationAccessWithModule('View', 'DataManagement') && (
                    <NavLink
                        className={({ isActive }) => (isActive ? 'header__link header__firstlink header-active' : 'header__link header__firstlink')}
                        // className="header__link header__firstlink" 
                        // exact 
                        // activeClassName="header-active" 
                        to="/">
                        Data Management
                    </NavLink>
                )}
                {dataStore.checkOperationAccessWithModule('View', 'ReportsManagement') && (
                    <NavLink
                        className={({ isActive }) => (isActive ? 'header__link header__otherlinks header-active' : 'header__link header__otherlinks')}
                        // className="header__link header__otherlinks" 
                        // activeClassName="header-active" 
                        to="/reports">
                        Reports
                    </NavLink>
                )}
                {/* 9/11/23 */}
                {dataStore.checkOperationAccessWithModule('View', 'FieldQualityDataEntry') && (
                    <NavLink
                        className={({ isActive }) => (isActive ? 'header__link header__otherlinks header-active' : 'header__link header__otherlinks')}
                        // className="header__link header__otherlinks" 
                        // activeClassName="header-active" 
                        to="/fieldQualityDataEntry">
                        Field Quality Data Entry
                    </NavLink>
                )}
            </Nav>
        );
    }
}

const HeaderRouterComponent = withRouter(HeaderComponent);
export default HeaderRouterComponent;
