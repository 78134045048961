import React, { Component } from 'react';
import { AccountStore } from '../stores/account-store';
import './_login.scss';
import logo from '../../../assets/Logos -_Plains - Navy.png';
import { NavigateFunction } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Button } from 'react-bootstrap';
import withRouter from '../../../withRouter';
import { dataStore } from 'features/common/stores/index'; // 18/8/23

interface LoginContainerProps {
    accountStore?: AccountStore;
    navigate: NavigateFunction;
}

@inject('accountStore')
@observer
class LoginContainerPage extends Component<LoginContainerProps & NavigateFunction> {
    constructor(props: LoginContainerProps) {
        super(props);
        if (this.props.accountStore?.getisLoggedIn) { //1/12/23
            // this.navigate('/');
            this.props?.navigate('/');
        }
    }
    componentDidUpdate(prevProps: Readonly<LoginContainerProps & NavigateFunction>, prevState: Readonly<{}>, snapshot?: any): void {
        if (prevProps.accountStore?.getisLoggedIn) { //1/12/23
            return this.props?.navigate('/');
        }
    }
    render(): React.ReactNode {
        return (
            <div className="login-container">
                <div className="login-container__content">
                    <div className="login-container__logo">
                        <img className="login-container-logo" src={logo} alt="Logo" />
                    </div>
                    <Button className="mt-3 mb-3 custom-btn" onClick={this.handleLogin}>Login</Button>
                    {/* <CustomButton type={CustomButtonType.Register} onClick={this.handleRegister}>
                        Sign Up
                    </CustomButton> */}
                </div>
                {/* <div className="login-container__row">
                    <button type="button" className="login-container__info"> i</button>
                </div> */}
            </div>
        );
    }

    handleLogin = () => {
        if (!this.props.accountStore?.getisLoggedIn) { //1/12/23
            localStorage.clear();
            sessionStorage.clear();
        }
        // 18/8/23
        let pathname: any = null;
        if (dataStore.checkOperationAccessWithModule('View', 'DataManagement')) {
            pathname = '/';
        } else if (dataStore.checkOperationAccessWithModule('View', 'ReportsManagement')) {
            pathname = '/reports';
        }
        this.props.accountStore?.login().then(() => {
            const redirectRoute = {
                pathname: pathname  // 18/8/23
                // state: this.navigate?.state
            };
            this.props?.navigate(redirectRoute);
        });
    };

    handleRegister = () => {
    };

    handleInfo = () => {
    };
}

const LoginContainer = withRouter(LoginContainerPage);
export default LoginContainer;
