import React, { Fragment, lazy } from 'react';
import LoginContainer from './features/account/components/login-container';
import { observer } from 'mobx-react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import HeaderRouterComponent from './shared/components/header/components';
import { accountStore } from './features/account/stores';
import ProtectedRoute from './shared/components/protected-route';
//import DataManagementDataComponent from 'features/data-management/components/datamanagement-data-component-new';
//import ReportManagementComponent from 'features/reports/components/report-management-component-new'; // repo
import { LicenseManager } from 'ag-grid-enterprise';
import { ReactLoader } from './shared/components/loader/loader';

const DataManagementDataComponent = lazy(() => import('features/data-management/components/datamanagement-data-component-new'))
const ReportManagementComponent = lazy(() => import('features/reports/components/report-management-component-new'))
// 9/11/23
const QualityDataEntryComponent = lazy(() => import('features/quality-data-entry/components/quality-data-entry-component'))

LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-055618}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Plains_Midstream_Canada_ULC}_is_granted_a_{Multiple_Applications}_Developer_License_for_{7}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{26_April_2025}____[v3]_[01]_MTc0NTYyMjAwMDAwMA==f2a7c6668420b0507914ba1177008f6d'
);
const App = (): React.JSX.Element => {
  return (
    <Fragment>
      <ReactLoader visible={false} />
      <ToastContainer
        position='top-center'
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
        className='toast-container'
      />
      {accountStore.getisLoggedIn ? ( //1/12/23
        <HeaderRouterComponent
          onLogout={accountStore.logOut}
          userName={accountStore.displayName}
          roles={accountStore.userRoles}
        />
      ) : null}
      <Routes>
        <Route path='/login' Component={LoginContainer} />
        <Route element={<ProtectedRoute
          roles={accountStore.userRoles}
          accountStore={accountStore}
          // exact
          path='/'
          component={DataManagementDataComponent}
        />}>
          <Route path='/' Component={DataManagementDataComponent} />
        </Route>
        {/* 18/8/23 */}
        <Route element={<ProtectedRoute
          roles={accountStore.userRoles}
          accountStore={accountStore}
          // exact
          path='/auditTrail'
          component={DataManagementDataComponent}
        />}>
          <Route path='/auditTrail' Component={DataManagementDataComponent} />
        </Route>
        <Route element={<ProtectedRoute
          roles={accountStore.userRoles}
          accountStore={accountStore}
          // exact
          path='/editData'
          component={DataManagementDataComponent}
        />}>
          <Route path='/editData' Component={DataManagementDataComponent} />
        </Route>
        <Route element={<ProtectedRoute
          roles={accountStore.userRoles}
          accountStore={accountStore}
          // exact
          path='/reports'
          component={ReportManagementComponent}
        />}>
          <Route path='/reports' Component={ReportManagementComponent} />
        </Route>
        <Route element={<ProtectedRoute
          roles={accountStore.userRoles}
          accountStore={accountStore}
          // exact
          path='/reports/sampleResult'
          component={ReportManagementComponent}
        />}>
          <Route path='/reports/sampleResult' Component={ReportManagementComponent} />
        </Route>
        {/* ad hoc */}
        <Route element={<ProtectedRoute
          roles={accountStore.userRoles}
          accountStore={accountStore}
          // exact
          path='/reports/adHocQueries'
          component={ReportManagementComponent}
        />}>
          <Route path='/reports/adHocQueries' Component={ReportManagementComponent} />
        </Route>
        {/* 9/11/23 */}
        <Route element={<ProtectedRoute
          roles={accountStore.userRoles}
          accountStore={accountStore}
          path='/fieldQualityDataEntry'
          component={QualityDataEntryComponent}
        />}>
          <Route path='/fieldQualityDataEntry' Component={QualityDataEntryComponent} />
        </Route>
      </Routes>
    </Fragment >
  );
};

// export default App;
export default observer(App);
