import * as React from "react";
import { toast, ToastOptions } from "react-toastify";
import { CustomToast, CustomToastType, ToastMessage } from './custom-toast';

export class ToastService {
    success(text: string, options: ToastOptions = {}, errors: ToastMessage[] = []): React.ReactNode {
        return toast(() => <CustomToast type={CustomToastType.SUCCESS} text={text} errors={errors} />, {
            //closeButton: null,
            ...options
        });
    }

    error(text: string = '', options: ToastOptions = {}, errors: ToastMessage[] = []): React.ReactNode {
        return toast(() => <CustomToast type={CustomToastType.ERROR} text={text} errors={errors} />, {
            //closeButton: null,
            ...options
        });
    }
    // 6/10/23
    warning(text: string = '', options: ToastOptions = {}, errors: ToastMessage[] = []): React.ReactNode {
        return toast(() => <CustomToast type={CustomToastType.WARNING} text={text} errors={errors} />, {
            //closeButton: null,
            ...options
        });
    }
    info(text: string, options: ToastOptions = {}, errors: ToastMessage[] = []): React.ReactNode {
        return toast(() => <CustomToast type={CustomToastType.INFO} text={text} errors={errors} />, {
            // closeButton: null,
            ...options
        });
    }
}
